<template>
    <div class="min-h-[15rem] space-y-10">
        <div v-if="activePage === undefined">
            Your verified contact methods allow you to both sign in and receive
            communications from the facility.
        </div>

        <div class="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-4">
            <div>
                <div class="flex items-center gap-2">
                    <div class="text-xl font-semibold">Phone Number</div>
                    <prime-button
                        v-if="!isEditing && profileData?.phoneNumber !== null"
                        label="Edit"
                        size="small"
                        icon="pi pi-pencil"
                        text
                        @click="handleEditPhone"
                    />
                    <prime-button
                        v-else-if="!isEditing"
                        text
                        severity="success"
                        label="Add"
                        icon="pi pi-plus"
                        size="small"
                        @click="handleEditPhone"
                    />
                </div>

                <div>
                    <prime-skeleton v-if="profileData === undefined" />
                    <template v-else>
                        <span
                            v-if="profileData.phoneNumber === null"
                            class="text-gray-500"
                        >
                            &mdash;
                        </span>
                        <span v-else>
                            {{ profileData.phoneNumber }}
                        </span>
                    </template>
                </div>
            </div>

            <div>
                <div class="flex items-center gap-2">
                    <div class="text-xl font-semibold">Email Address</div>
                    <prime-button
                        v-if="!isEditing && profileData?.emailAddress !== null"
                        label="Edit"
                        text
                        icon="pi pi-pencil"
                        size="small"
                        @click="handleEditEmail"
                    />
                    <prime-button
                        v-else-if="!isEditing"
                        severity="success"
                        label="Add"
                        size="small"
                        icon="pi pi-plus"
                        text
                        @click="handleEditEmail"
                    />
                </div>
                <div>
                    <prime-skeleton v-if="profileData === undefined" />
                    <template v-else>
                        <span
                            v-if="profileData.emailAddress === null"
                            class="text-gray-500"
                        >
                            &mdash;
                        </span>
                        <span v-else>
                            {{ profileData.emailAddress }}
                        </span>
                    </template>
                </div>
            </div>
        </div>

        <enter-new-email
            v-if="activePage === 'email'"
            :suggested-email="suggestedEmailAddress"
            :current-profile-email="profileData?.emailAddress ?? undefined"
            @cancel="activePage = undefined"
            @done="handleEmailDone"
        />

        <enter-new-phone-number
            v-if="activePage === 'phone'"
            :suggested-phone-number="suggestedPhoneNumber"
            :current-profile-number="profileData?.phoneNumber ?? undefined"
            @cancel="activePage = undefined"
            @done="handlePhoneDone"
        />

        <prime-message
            v-if="updatedMessage !== ''"
            severity="success"
            :closable="false"
        >
            {{ updatedMessage }}
        </prime-message>
    </div>
</template>

<script lang="ts">
import { graphql } from '~/resources/graphql'

export const EditProfileViewerFragment = graphql(/* GraphQL */ `
    fragment EditProfileViewerFragment on Profile {
        primaryCustomer {
            commaName
            email
            mobilePhone
        }
        emailAddress
        phoneNumber
    }
`)
</script>

<script setup lang="ts">
import { useRpQuery } from '~/composables/graphql'
import { useQuery } from '@tanstack/vue-query'
import { computed, onUnmounted, ref, watchEffect } from 'vue'
import EnterNewEmail from '~/components/Modals/EditProfile/EnterNewEmail.vue'
import EnterNewPhoneNumber from '~/components/Modals/EditProfile/EnterNewPhoneNumber.vue'
import PrimeButton from 'primevue/button'
import PrimeSkeleton from 'primevue/skeleton'
import type { FragmentType } from '~/resources/graphql'
import { getFragmentData } from '~/resources/graphql'
import type { EditProfileViewerFragmentFragment } from '~/resources/graphql/graphql'
import PrimeMessage from 'primevue/message'

const profileData = ref<EditProfileViewerFragmentFragment>()
const query = useRpQuery()
const { data } = useQuery({
    queryKey: ['EditProfileQuery'],
    placeholderData: previousData => previousData,
    queryFn: () =>
        query(
            graphql(/* GraphQL */ `
                query EditProfileQuery {
                    viewer {
                        ...EditProfileViewerFragment
                    }
                }
            `)
        )
})

watchEffect(() => {
    if (data.value !== undefined && data.value.viewer !== null) {
        profileData.value = getFragmentData(
            EditProfileViewerFragment,
            data.value.viewer
        )
    }
})

const suggestedEmailAddress = computed(() => {
    let suggested: string
    if (
        profileData.value?.emailAddress !== undefined &&
        profileData.value.emailAddress !== null &&
        profileData.value.emailAddress !== ''
    ) {
        suggested = profileData.value.emailAddress
    } else {
        suggested = profileData.value?.primaryCustomer.email ?? ''
    }
    return suggested
})

const suggestedPhoneNumber = computed(() => {
    let suggested: string
    if (
        profileData.value?.phoneNumber !== undefined &&
        profileData.value.phoneNumber !== null &&
        profileData.value.phoneNumber !== ''
    ) {
        suggested = profileData.value.phoneNumber
    } else {
        suggested = profileData.value?.primaryCustomer.mobilePhone ?? ''
    }
    return suggested
})

const activePage = ref<'email' | 'phone'>()
const isEditing = computed(() => activePage.value !== undefined)
function handleEditEmail() {
    activePage.value = 'email'
    updatedMessage.value = ''
}

function handleEditPhone() {
    activePage.value = 'phone'
    updatedMessage.value = ''
}

const updatedMessage = ref('')
let updatedMessageTimeout: number | undefined
function handlePhoneDone(
    newData: FragmentType<typeof EditProfileViewerFragment>
) {
    activePage.value = undefined
    profileData.value = getFragmentData(EditProfileViewerFragment, newData)
    updatedMessage.value = 'Phone number changed!'
    updatedMessageTimeout = setTimeout(() => {
        updatedMessage.value = ''
        updatedMessageTimeout = undefined
    }, 3000)
}

function handleEmailDone(
    newData: FragmentType<typeof EditProfileViewerFragment>
) {
    activePage.value = undefined
    profileData.value = getFragmentData(EditProfileViewerFragment, newData)
    updatedMessage.value = 'Email address changed!'
    updatedMessageTimeout = setTimeout(() => {
        updatedMessage.value = ''
        updatedMessageTimeout = undefined
    }, 3000)
}

onUnmounted(() => {
    if (updatedMessageTimeout !== undefined) {
        clearTimeout(updatedMessageTimeout)
    }
})
</script>
