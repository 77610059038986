<template>
    <form
        class="space-y-4"
        @submit="onSubmit"
    >
        <div>
            <div class="text-lg font-medium text-gray-600">
                Please enter the phone number you would like to sign in with:
            </div>
            <div>
                <phone-number
                    v-bind="phoneValue"
                    :initial-value="suggestedPhoneNumber"
                    :autofocus="false"
                    :disabled="tokenData !== undefined"
                />
                <span
                    v-if="errors.phone"
                    id="phone-error"
                    class="text-small text-red-600"
                    >{{ errors.phone }}</span
                >
            </div>
        </div>

        <div
            v-if="tokenData === undefined"
            class="text-sm"
        >
            We'll send you a verification code to ensure the number is correct.
        </div>

        <div
            v-if="tokenData === undefined"
            class="flex gap-8"
        >
            <prime-button
                label="Cancel"
                outlined
                class="w-full"
                severity="secondary"
                :disabled="isPending"
                @click="$emit('cancel')"
            />

            <prime-button
                type="submit"
                label="Send Verification Code"
                class="w-full"
                severity="secondary"
                :loading="isPending"
            />
        </div>

        <template v-if="tokenData !== undefined">
            <div class="flex items-end justify-between">
                <div class="whitespace-nowrap font-medium text-gray-600">
                    Please enter the verification code we sent:

                    <div ref="codeRef">
                        <input-text
                            v-model="code"
                            placeholder="XXXXXX"
                            :disabled="isPending || isCodePending"
                            @keydown.enter="verifyCode"
                        />
                    </div>
                </div>

                <div class="text-right text-sm">
                    Didn't receive your code?
                    <div>
                        <prime-button
                            type="submit"
                            link
                            size="small"
                            label="Send New Code"
                            :disabled="isPending || isCodePending"
                        />
                    </div>
                </div>
            </div>

            <div class="text-red-600 font-medium">
                {{ incorrectCodeMessage || '&nbsp;' }}
            </div>

            <div class="flex gap-8">
                <prime-button
                    label="Cancel"
                    outlined
                    class="w-full"
                    severity="secondary"
                    :disabled="isPending || isCodePending"
                    @click="$emit('cancel')"
                />

                <prime-button
                    label="Submit"
                    class="w-full"
                    severity="secondary"
                    :loading="isPending || isCodePending"
                    @click="verifyCode"
                />
            </div>
        </template>
    </form>
</template>

<script setup lang="ts">
import PhoneNumber from '~/components/Input/PhoneNumber.vue'
import PrimeButton from 'primevue/button'
import { toTypedSchema } from '@vee-validate/yup'
import { boolean, object, string } from 'yup'
import { useForm } from 'vee-validate'
import { onMounted, ref, watchEffect } from 'vue'
import InputText from 'primevue/inputtext'
import { useRpQuery } from '~/composables/graphql'
import { useMutation } from '@tanstack/vue-query'
import type {
    SendVerifyPhoneCodeInput,
    VerifyContactCodeInput
} from '~/resources/graphql/graphql'
import type { FragmentType } from '~/resources/graphql'
import { graphql } from '~/resources/graphql'
import { useShowAlert } from '~/composables/use-show-alert'
import { EditProfileViewerFragment } from '~/components/Settings/EditProfile.vue'

const props = defineProps<{
    suggestedPhoneNumber: string
    currentProfileNumber: string | undefined
}>()

const emit = defineEmits<{
    (e: 'cancel'): void
    (e: 'done', payload: FragmentType<typeof EditProfileViewerFragment>): void
}>()

onMounted(() => {
    setTimeout(() => {
        document.getElementById('mobile_phone')?.focus?.()
    }, 30)
})

const { errors, defineComponentBinds, handleSubmit } = useForm({
    validationSchema: toTypedSchema(
        object({
            phone: object()
                .shape({
                    formatted: string(),
                    number: string(),
                    valid: boolean()
                })
                .test({
                    message: 'Please enter a valid phone number',
                    test: value => value.valid === true
                })
        })
    )
})

const phoneValue = defineComponentBinds('phone', {
    validateOnModelUpdate: false,
    validateOnBlur: true
})

const query = useRpQuery()
const { mutate, isPending } = useMutation({
    mutationFn: (input: SendVerifyPhoneCodeInput) =>
        query(
            graphql(/** @lang GraphQL */ `
                mutation SendVerifyPhoneCode(
                    $input: SendVerifyPhoneCodeInput!
                ) {
                    sendVerifyPhoneCode(input: $input) {
                        __typename
                        ... on SendVerifyPhoneCodeResult {
                            token
                        }
                    }
                }
            `),
            {
                input
            }
        )
})

const showAlert = useShowAlert()
const codeRef = ref<HTMLDivElement>()
const tokenData = ref<{ phone: string; token: string }>()
const onSubmit = handleSubmit(values => {
    if (values.phone.number === props.currentProfileNumber) {
        showAlert(
            'Heads Up!',
            "The phone number you've entered is the same."
        ).then(() => {
            setTimeout(() => {
                document.getElementById('mobile_phone')?.focus?.()
            }, 30)
        })
        return
    }

    mutate(
        { phone: values.phone.number },
        {
            onSuccess: data => {
                if (
                    data.sendVerifyPhoneCode.__typename ===
                    'SendVerifyPhoneCodeResult'
                ) {
                    tokenData.value = {
                        phone: values.phone.number,
                        token: data.sendVerifyPhoneCode.token
                    }

                    setTimeout(() => {
                        codeRef.value?.querySelector('input')?.select?.()
                    }, 30)
                    return
                }

                throw new Error()
            }
        }
    )
})

const code = ref('')
watchEffect(() => {
    if (/^\d{6}$/.test(code.value)) {
        verifyCode()
    }
})

const { mutate: mutateVerifyCode, isPending: isCodePending } = useMutation({
    mutationFn: (input: VerifyContactCodeInput) =>
        query(
            graphql(/** @lang GraphQL */ `
                mutation VerifyContactCode($input: VerifyContactCodeInput!) {
                    verifyContactCode(input: $input) {
                        __typename
                        ... on VerifyContactCodeResult {
                            record {
                                ...EditProfileViewerFragment
                            }
                        }
                        ... on IncorrectCodeProblem {
                            message
                        }
                    }
                }
            `),
            {
                input
            }
        )
})

const incorrectCodeMessage = ref()
function verifyCode() {
    if (!/^\d{6}$/.test(code.value)) {
        incorrectCodeMessage.value = 'Your code should be six numbers.'
        return
    }

    if (tokenData.value === undefined) {
        return
    }

    mutateVerifyCode(
        {
            token: tokenData.value.token,
            code: code.value
        },
        {
            onSuccess: data => {
                if (
                    data.verifyContactCode.__typename ===
                    'VerifyContactCodeResult'
                ) {
                    emit('done', data.verifyContactCode.record)
                } else if (
                    data.verifyContactCode.__typename === 'IncorrectCodeProblem'
                ) {
                    incorrectCodeMessage.value = data.verifyContactCode.message
                    setTimeout(() => {
                        codeRef.value?.querySelector('input')?.select?.()
                    }, 30)
                }
                throw new Error()
            }
        }
    )
}
</script>
