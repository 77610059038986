<template>
    <form
        class="space-y-4"
        @submit="onSubmit"
    >
        <div>
            <div class="text-lg font-medium text-gray-600">
                Please enter the email address you would like to sign in with:
            </div>
            <div>
                <span class="p-input-icon-left w-full">
                    <i class="pi pi-envelope"></i>
                    <input-text
                        id="email"
                        v-bind="emailValue"
                        inputmode="email"
                        type="email"
                        class="w-full"
                        :class="{ 'p-invalid': errors.email }"
                        placeholder="Email Address"
                        aria-describedby="email-error"
                        :disabled="tokenData !== undefined"
                    />
                </span>
                <span
                    v-if="errors.email"
                    id="email-error"
                    class="text-small text-red-600"
                    >{{ errors.email }}</span
                >
            </div>
        </div>

        <div
            v-if="tokenData === undefined"
            class="text-sm"
        >
            We'll send you a verification code to ensure the email is correct.
        </div>

        <div
            v-if="tokenData === undefined"
            class="flex gap-8"
        >
            <prime-button
                label="Cancel"
                outlined
                class="w-full"
                severity="secondary"
                :disabled="isPending"
                @click="$emit('cancel')"
            />

            <prime-button
                type="submit"
                label="Send Verification Code"
                class="w-full"
                severity="secondary"
                :loading="isPending"
            />
        </div>

        <template v-if="tokenData !== undefined">
            <div class="flex items-end justify-between">
                <div class="whitespace-nowrap font-medium text-gray-600">
                    Please enter the verification code we sent:

                    <div ref="codeRef">
                        <input-text
                            v-model="code"
                            placeholder="XXXXXX"
                            :disabled="isPending || isCodePending"
                            @keydown.enter="verifyCode"
                        />
                    </div>
                </div>

                <div class="text-right text-sm">
                    Didn't receive your code?
                    <div>
                        <prime-button
                            type="submit"
                            link
                            size="small"
                            label="Send New Code"
                            :disabled="isPending || isCodePending"
                        />
                    </div>
                </div>
            </div>

            <div class="text-red-600 font-medium">
                {{ incorrectCodeMessage || '&nbsp;' }}
            </div>

            <div class="flex gap-8">
                <prime-button
                    label="Cancel"
                    outlined
                    class="w-full"
                    severity="secondary"
                    :disabled="isPending || isCodePending"
                    @click="$emit('cancel')"
                />

                <prime-button
                    label="Submit"
                    class="w-full"
                    severity="secondary"
                    :loading="isPending || isCodePending"
                    @click="verifyCode"
                />
            </div>
        </template>
    </form>
</template>

<script setup lang="ts">
import PrimeButton from 'primevue/button'
import { toTypedSchema } from '@vee-validate/yup'
import { object, string } from 'yup'
import { useForm } from 'vee-validate'
import { onMounted, ref, watchEffect } from 'vue'
import InputText from 'primevue/inputtext'
import { useRpQuery } from '~/composables/graphql'
import { useMutation } from '@tanstack/vue-query'
import type {
    SendVerifyEmailCodeInput,
    VerifyContactCodeInput
} from '~/resources/graphql/graphql'
import type { FragmentType } from '~/resources/graphql'
import { graphql } from '~/resources/graphql'
import { useShowAlert } from '~/composables/use-show-alert'
import { EditProfileViewerFragment } from '~/components/Settings/EditProfile.vue'

const props = defineProps<{
    suggestedEmail: string
    currentProfileEmail: string | undefined
}>()

const emit = defineEmits<{
    (e: 'cancel'): void
    (e: 'done', payload: FragmentType<typeof EditProfileViewerFragment>): void
}>()

onMounted(() => {
    setTimeout(() => {
        document.getElementById('email')?.focus?.()
    }, 30)
})

const { errors, defineComponentBinds, handleSubmit } = useForm({
    validationSchema: toTypedSchema(
        object({
            email: string()
                .required()
                .label('Email')
                .email('Please enter a valid email')
        })
    ),
    initialValues: {
        email: props.suggestedEmail
    }
})

const emailValue = defineComponentBinds('email', {
    validateOnModelUpdate: false,
    validateOnBlur: true
})

const query = useRpQuery()
const { mutate, isPending } = useMutation({
    mutationFn: (input: SendVerifyEmailCodeInput) =>
        query(
            graphql(/** @lang GraphQL */ `
                mutation SendVerifyEmailCode(
                    $input: SendVerifyEmailCodeInput!
                ) {
                    sendVerifyEmailCode(input: $input) {
                        __typename
                        ... on SendVerifyEmailCodeResult {
                            token
                        }
                    }
                }
            `),
            {
                input
            }
        )
})

const showAlert = useShowAlert()
const codeRef = ref<HTMLDivElement>()
const tokenData = ref<{ email: string; token: string }>()
const onSubmit = handleSubmit(values => {
    if (values.email === props.currentProfileEmail) {
        showAlert(
            'Heads Up!',
            "The email address you've entered is the same."
        ).then(() => {
            setTimeout(() => {
                document.getElementById('email')?.focus?.()
            }, 30)
        })
        return
    }

    mutate(
        { email: values.email },
        {
            onSuccess: data => {
                if (
                    data.sendVerifyEmailCode.__typename ===
                    'SendVerifyEmailCodeResult'
                ) {
                    tokenData.value = {
                        email: values.email,
                        token: data.sendVerifyEmailCode.token
                    }

                    setTimeout(() => {
                        codeRef.value?.querySelector('input')?.select?.()
                    }, 30)
                    return
                }

                throw new Error()
            }
        }
    )
})

const code = ref('')
watchEffect(() => {
    if (/^\d{6}$/.test(code.value)) {
        verifyCode()
    }
})

const { mutate: mutateVerifyCode, isPending: isCodePending } = useMutation({
    mutationFn: (input: VerifyContactCodeInput) =>
        query(
            graphql(/** @lang GraphQL */ `
                mutation VerifyContactCode($input: VerifyContactCodeInput!) {
                    verifyContactCode(input: $input) {
                        __typename
                        ... on VerifyContactCodeResult {
                            record {
                                ...EditProfileViewerFragment
                            }
                        }
                        ... on IncorrectCodeProblem {
                            message
                        }
                    }
                }
            `),
            {
                input
            }
        )
})

const incorrectCodeMessage = ref()
function verifyCode() {
    if (!/^\d{6}$/.test(code.value)) {
        incorrectCodeMessage.value = 'Your code should be six numbers.'
        return
    }

    if (tokenData.value === undefined) {
        return
    }

    mutateVerifyCode(
        {
            token: tokenData.value.token,
            code: code.value
        },
        {
            onSuccess: data => {
                if (
                    data.verifyContactCode.__typename ===
                    'VerifyContactCodeResult'
                ) {
                    emit('done', data.verifyContactCode.record)
                } else if (
                    data.verifyContactCode.__typename === 'IncorrectCodeProblem'
                ) {
                    incorrectCodeMessage.value = data.verifyContactCode.message
                    setTimeout(() => {
                        codeRef.value?.querySelector('input')?.select?.()
                    }, 30)
                }
                throw new Error()
            }
        }
    )
}
</script>
